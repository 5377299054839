const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://smachylo.com',
  title: 'Portfolio',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Bohdan Smachylo',
  role: 'Full stack Engineer',
  description:
    'I am a dedicated Full Stack Developer with a passion for creating seamless and efficient web applications. With a strong focus on both front-end and back-end development, I enjoy turning complex problems into user-friendly solutions. I thrive in collaborative environments, where I can work closely with teams to bring ideas to life, always aiming for clean, maintainable code and intuitive user experiences. My adaptability and continuous learning mindset ensure that I stay up-to-date with the latest technologies, enabling me to deliver high-quality projects that meet and exceed client expectations.',
  // resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/bohdansmachylo/',
    github: 'https://github.com/smachylooo',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: '2048',
    image: '../images/2048.jpg',
    description:
      'The clean design focuses on gameplay, making it easy for users to get started and enjoy the challenge of 2048.',
    stack: ['SASS', 'HTML', 'JS'],
    sourceCode: 'https://github.com/smachylooo/js_2048_game/tree/develop',
    livePreview: 'https://smachylooo.github.io/js_2048_game/',
  },
  {
    name: 'Todo',
    image: '../images/todo.jpg',
    description:
      'This To-Do App is a user-friendly task management tool that allows you to easily organize your daily tasks.',
    stack: ['React', 'TS', 'SASS',],
    sourceCode: 'https://github.com/smachylooo/react_todo-app-add-and-delete/tree/develop',
    livePreview: 'https://smachylooo.github.io/react_todo-app-add-and-delete/',
  },
  {
    name: 'Galaxy',
    image: '../images/galaxy.jpg',
    description:
      'Here\'s a small project I created with Three.js: a galaxy generator that simulates a dynamic, star-filled galaxy in 3D space.',
    stack: ['THREE', 'JS'],
    sourceCode: 'https://github.com/smachylooo/galaxy',
    livePreview: 'https://smachylooo.github.io/galaxy/',
  },
  {
    name: 'Animeted fox',
    image: '../images/fox.jpg',
    description:
      'A 3D animated fox created using Three.js to practice and explore 3D graphics. This project showcases smooth animations and intricate details, demonstrating skills in 3D modeling and JavaScript.',
    stack: ['THREE', 'JS'],
    sourceCode: 'https://github.com/smachylooo/3d-fox/',
    livePreview: 'https://smachylooo.github.io/3d-fox/#debug',
  },
  {
    name: 'Tip counter',
    image: '../images/tip.jpg',
    description:
      'A tip counter app in React class components calculates and displays the total tip and per-person share based on the bill amount, number of people, and tip percentage.',
    stack: ['REACT', 'JS', 'CSS'],
    sourceCode: 'https://github.com/smachylooo/tip-counter',
    livePreview: 'https://smachylooo.github.io/tip-counter',
  },
  {
    name: 'First Game',
    image: '../images/game.jpg',
    description:
      'I created my first simple web game using React Three Fiber (R3F), integrating 3D elements into a browser-based experience.',
    stack: ['R3F', 'React', 'JS'],
    sourceCode: 'https://github.com/smachylooo/three_game',
    livePreview: 'https://smachylobohdan-fastgame-c66zui9ow-bohdans-projects-0399d775.vercel.app/',
  },
  {
    name: 'Dia landing',
    image: '../images/dia.jpg',
    description:
      'The landing page design features a clean and modern layout for a strategic creative agency.',
    stack: ['SASS', 'HTML'],
    sourceCode: 'https://github.com/smachylooo/layout_dia/tree/develop',
    livePreview: 'https://smachylooo.github.io/layout_dia/',
  },
  {
    name: 'Nothing landing',
    image: '../images/nothing.jpg',
    description:
      'I developed a sleek and modern landing page for a tech store, focusing on an intuitive user experience and cutting-edge design.',
    stack: ['SASS', 'HTML'],
    sourceCode: 'https://github.com/smachylooo/layout_landing-page/tree/develop',
    livePreview: 'https://smachylooo.github.io/layout_landing-page/',
  },
  {
    name: 'Haunted House',
    image: '../images/house.jpg',
    description:
      "Here's my first project in Three.js: a spooky haunted house with eerie lighting and a dark atmosphere.",
    stack: ['THREE', 'JS'],
    sourceCode: 'https://github.com/smachylooo/haunted_house',
    livePreview: 'https://ghosthouse-one.vercel.app/',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Redux',
  'SASS',
  'React Native',
  'Three',
  'Git',
  'Jest',
  'Figma',
  'Vue',
  'Node',
  'Postman',
  'Sql',
  'NoSql'
]

const contact = {
  email: 'contact@smachylo.com ',
}

export { header, about, projects, skills, contact }
