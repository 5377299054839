import { useEffect, useState } from 'react'
import uniqid from 'uniqid'
import { projects } from '../../data'
import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Projects.css'

const Projects = () => {
  const [localData, setLocalData] = useState(projects.slice(0, 3));
  const [showButton, setShowButton] = useState(true);
  
  const handleClickLoader = (e) => {
    e.preventDefault();

    setLocalData(prevData => [...prevData, ...projects.slice(localData.length, localData.length + 3)])
  }

  useEffect(() => {
    if (projects.length === localData.length) {
      setShowButton(false);
    }
  }, [localData])
  
  if (!projects.length) return null

  return (
    <>
      <section id='projects' className='section projects'>
        <h2 className='section__title'>Projects</h2>

        <div className='projects__grid'>
          {localData.map((project) => (
            <ProjectContainer key={uniqid()} project={project} />
          ))}
        </div>
        {showButton && (
          <div className='center' style={{ justifyContent: 'center', marginTop: '35px' }}>
            <button type='button' onClick={handleClickLoader}>
              <span className='btn btn--outline'>
                Load more
              </span>
            </button>
          </div>
        )}
      </section>
    </>
  )
}

export default Projects
